import React from 'react';
import SelectBox from './SelectBox';
import PropType from 'prop-types';
import axios from 'axios';
import {List} from 'immutable';
import {disableWheelInput} from "../scripts/form_utilities";

class BlockExaminationForm extends React.PureComponent {

    state = {
        kind: null,
        kinds: List(this.props.kinds),
        blockInstanceId: this.props.blockInstanceId,
        blockInstances: List(),
        missingDays: 0
    }

    selectKind = React.createRef();

    async componentDidMount() {

        const result = await axios.get('/lecturers/block_instances', { headers: {'Accept': 'application/json'}});

        if(result.status === 200) {
            this.setState({blockInstances: List(result.data).filter(instance => instance.examined < instance.examination_required)});
        }

    }

    onBlockInstanceChange = async (item) => {

        const instance = this.state.blockInstances.find(instance => instance.id === item.value);

        const missingDays = instance.examination_required - instance.examined;

        this.setState({blockInstance: instance, missingDays: missingDays});

    }

    onKindChange = (item) => {
        this.setState({kind: item.value});
    }

    render() {

        return (
            <React.Fragment>

                {this.state.blockInstances.size > 0 &&
                    <React.Fragment>
                        <div className='form-group'>
                            <label className='required' htmlFor='block_examination_block_instance_id'>{this.props.labelBlockInstance}</label>
                            <SelectBox id='block_examination_block_instance_id' name='block_examination[block_instance_id]' required={true} disableInput={true} items={this.state.blockInstances.map(item => {return {value: item.id, title: item.name}}).toJS()} onChange={this.onBlockInstanceChange} value={this.state.blockInstanceId ? this.state.blockInstanceId : ''}/>
                        </div>

                        <div className='form-group'>
                            <label className='required' htmlFor='block_examination_description'>{this.props.labelDescription}</label>
                            <input className='form-control' type='text' id='block_examination_description' name='block_examination[description]' />
                        </div>

                        <div className='form-group'>
                            <label className='required' htmlFor='block_examination_kind'>{this.props.labelKind}</label>
                            <SelectBox id='block_examination_kind' name='block_examination[kind]' ref={this.selectKind} required={true} disableInput={true} items={this.state.kinds.toJS()} onChange={this.onKindChange} />
                        </div>

                        <div className='form-group'>
                            <label className='required' htmlFor='block_examination_days' >{`${this.props.labelDays} (Max. ${this.state.missingDays})`}</label>
                            <input className='form-control' type='number' id='block_examination_days' name='block_examination[days]' required={true} min={1} max={this.state.missingDays} onWheel={disableWheelInput}/>
                        </div>

                        </React.Fragment>
                }

                {this.state.blockInstances.size === 0 &&
                    <div className='alert alert-info'>
                        Es sind keine Blockleistungen zu erbringen.
                    </div>
                }

                <div className='row'>
                    <div className='col'>
                        {this.state.blockInstances.size > 0 &&
                            <input type="submit" name="commit" value={this.props.labelAdd} className="btn btn-primary"/>
                        }
                    </div>

                    <div className='col-auto'>
                        <a className="btn btn-default" href={this.props.backLink}>{this.props.labelBack}</a>
                    </div>
                </div>

            </React.Fragment>
        )

    }

}

BlockExaminationForm.propTypes = {
    kinds: PropType.array.isRequired,
    labelDays: PropType.string.isRequired,
    labelKind: PropType.string.isRequired,
    labelDescription: PropType.string.isRequired,
    labelBlockInstance: PropType.string.isRequired,
    labelAdd: PropType.string.isRequired,
    labelBack: PropType.string.isRequired,
    backLink: PropType.string.isRequired,
    blockInstanceId: PropType.number
}

export default BlockExaminationForm;