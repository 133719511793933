import React, {useState} from 'react';
import SelectBox from "./SelectBox";
import DropZone from "./DropZone";


const requestInfo = ({
                         title,
                         value,
                         description,
                         topics,
                         labels,
                         index,
                         setSelectedBlocks,
                         setSelectionOptions
                     }) => {

    const ratings = [{value: 5, label: labels.ratingBad}, {value: 4, label: labels.ratingPoor}, {
        value: 3,
        label: labels.ratingAverage
    }, {value: 2, label: labels.ratingGreat}, {value: 1, label: labels.ratingExcellent}]

    const deleteRequest = () => {
        setSelectionOptions(prev => [{title: title, value: value, topics: topics}, ...prev])
        setSelectedBlocks(prev => {
            prev.splice(index, 1)
            return prev
        })
    }

    return (
            <div className={'card mt-3'} >
                <input type={"hidden"} name={`lecturer[qualification_requests_attributes][${index}][block_id]`}
                       value={value}/>
                <div className={'card-heading border bottom bg-gray'}>
                    <div className={"d-flex justify-content-between align-items-center"}>
                        <a href={`#collapse_${index}`} data-toggle="collapse"
                           aria-expanded="true" aria-controls={`collapse_${index}`} role='button'>
                            <h4 className={"card-title"}>
                                {title} <i className={"icon fa-light fa-arrow-circle-down"}></i>
                            </h4>
                        </a>
                        <div
                                    onClick={() => deleteRequest()}
                                    title={labels.deleteRequest}
                                    className="btn btn-danger btn-icon btn-rounded p-0 font-size-18 d-flex justify-content-center align-items-center">
                                <i className="fa-light fa-trash-alt"></i>
                            </div>
                    </div>
                </div>
                <div id={`collapse_${index}`} className={'card-body collapse show'}>
                    <div className={"form-group"}>
                        <p>{description}</p>
                    </div>
                    <div className={"form-group"}>
                        <label>{labels.topics}</label>
                        <ul className={"list-unstyled"}>
                            {topics.map(topic => (<li>{topic}</li>))}
                        </ul>
                    </div>
                    <div className={"form-group"}>
                        <label className={"required"}>{labels.starRating}</label>
                        <div className={"star-rating-container"}>
                            <div className={"star-rating"}>
                                {ratings.map((rating) => {
                                    const field_id = `id_${index}_${5 - (rating.value - 1)}`
                                    return (
                                            <>

                                                <input name={`lecturer[qualification_requests_attributes][${index}][star_rating]`}
                                                       id={field_id} value={rating.value}
                                                       className={`radio-${5 - (rating.value - 1)}`} type={'radio'}
                                                       required/>
                                                <label htmlFor={field_id}
                                                       title={ratings[(rating.value - 1)].label}
                                                       className={`star star-${5 - (rating.value - 1)}`}></label>
                                            </>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor={`lecturer[qualification_requests_attributes][${index}][reasoning]`} className={"required"}>{labels.reasoning}</label>
                        <textarea name={`lecturer[qualification_requests_attributes][${index}][reasoning]`} required
                                  className={"form-control"}/>
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor={`lecturer[qualification_requests_attributes][${index}][theoretical_experiences][]`}
                               className="required">
                            {labels.theoreticalExperiences}
                        </label>
                        <DropZone
                                name={`lecturer[qualification_requests_attributes][${index}][theoretical_experiences][]`}
                                buttonLabel={labels.uploadButtonLabel} description={labels.theoreticalExperiences}
                                multiple={true} required/>
                    </div>
                    <div className={"form-group"}>
                        <label htmlFor={`lecturer[qualification_requests_attributes][${index}][practical_experiences][]`}
                               className="required">
                            {labels.practicalExperiences}
                        </label>
                        <DropZone
                                name={`lecturer[qualification_requests_attributes][${index}][practical_experiences][]`}
                                buttonLabel={labels.uploadButtonLabel} description={labels.practicalExperiences}
                                multiple={true} required/>
                    </div>
                </div>
            </div>

    )
}

function PedagogicalSuitability({labels, blockVersions}) {
    const [selectionOptions, setSelectionOptions] = useState(blockVersions)
    const [selectedBlocks, setSelectedBlocks] = useState([])
    const handleChange = (event) => {
        setSelectedBlocks((prevState) => [...prevState, event])
        setSelectionOptions((prevState => [...prevState.filter(item => item.value!=event.value)]))
    }
    return (
            <>
                <div className="form-group">
                    <label className={"required"}>{labels.blockSelection}</label>
                    <SelectBox id={"requestedBlock"} items={selectionOptions}
                               onChange={handleChange} value={selectedBlocks}/>
                    {selectedBlocks.map((selection, index) => requestInfo({
                        ...selection,
                        index: index,
                        labels,
                        setSelectedBlocks,
                        setSelectionOptions
                    }))}
                </div>
            </>
    );
}

export default PedagogicalSuitability;