import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {List, OrderedMap} from 'immutable';
import SelectBox from './SelectBox';

class Permissions extends React.PureComponent {

    state = {
        permissions: OrderedMap(),
        selection: null
    }

    constructor(props) {

        super(props);

        this.resourceTitles = props.resources.reduce(function(map, resource) {
            map[resource.value] = { value: resource.value, title: resource.title };
            return map;
        }, {});

    }

    async componentDidMount() {

        const response = await axios.get(location.href, {
            headers: {
                'Accept': 'application/json'
            }
        });

        if(response.status === 200) {
            this.setState({permissions: OrderedMap(response.data)});
        }

    }

    persistPermissions = async (permissions) => {

        const result = await axios.post(location.href, { permissions: permissions.toJS() }, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if(result.status === 200) {
            this.setState({permissions: OrderedMap(result.data)});
        }

    }

    onPermissionChange = async (resource, action) => {

        const previousActions = List(this.state.permissions.get(resource));
        let actions;

        if(previousActions.includes(action)) {
            actions = previousActions.delete(previousActions.indexOf(action));
        } else {
            actions = previousActions.push(action);
        }

        await this.persistPermissions(this.state.permissions.set(resource, actions.toArray()));

    }

    onPermissionRemove = async (key) => {
        await this.persistPermissions(this.state.permissions.delete(key));
    }

    onAddResource = () => {
        this.setState({permissions: this.state.permissions.set(this.state.selection, [])});
        this.setState({ selection: null });
    }

    onSelectionChange = (item) => {
        this.setState({ selection: item.value });
    }

    disableButton = () => {
        return !this.state.selection || (this.state.permissions.size !== 0 && this.state.permissions.last().length === 0);
    }

    render() {

        const resourceActions = this.props.resources.reduce((map, resource) => {map[resource.value] = resource.actions; return map}, {})
        const unpermittedResources = this.props.resources.filter(r => !this.state.permissions.has(r.value))

        return (
            <table id='permissions' className='table table-hover table-striped-vertical fixed-header'>
                <thead>
                <tr>
                    <th />
                    { this.props.actions.map((action, idx) => <th key={idx} className='text-center'>{action.title}</th>) }
                    {this.props.editable &&
                        <th className='action'/>
                    }
                </tr>
                </thead>
                <tbody>
                    {
                        this.state.permissions.entrySeq().map((permission, idx) => {

                            let resource = permission.shift()
                            resource = this.resourceTitles[resource]

                            if(!resource) {
                                return null
                            }

                            return (
                                <tr key={idx}>
                                    <td>{resource.title}</td>
                                    {
                                        this.props.actions.map((action, idx) =>
                                            <td key={idx} className='text-center'>
                                                { this.props.editable && resourceActions[resource.value].includes(action.value) &&
                                                    <div className='checkbox checkbox-without-label'>
                                                        <input type='checkbox' id={`${resource.title}_${action.value}`} checked={permission[0].includes(action.value)} onChange={() => this.onPermissionChange(resource.value, action.value)} />
                                                        <label htmlFor={`${resource.title}_${action.value}`} />
                                                    </div>
                                                }
                                                {!this.props.editable && resourceActions[resource.value].includes(action.value) &&
                                                    <i className='fa-solid fa-check'/>
                                                }
                                            </td>
                                        )
                                    }
                                    {this.props.editable &&
                                        <td className='action'>
                                            <button className='btn btn-icon btn-flat btn-rounded'
                                                    onClick={() => this.onPermissionRemove(resource.value)}>
                                                <i className='fa-solid fa-trash text-danger'/>
                                            </button>
                                        </td>
                                    }
                                </tr>
                            )

                        })
                    }
                    {this.props.editable && unpermittedResources.length > 0 &&
                        <tr>
                            <td>
                                <SelectBox id='resources' name='resource' placeholder='Resource' items={unpermittedResources} onChange={(item) => this.onSelectionChange(item)} />
                            </td>
                            <td colSpan={this.props.actions.length + 1}>
                                <button type='button' className='btn btn-primary m-0' onClick={this.onAddResource} disabled={this.disableButton()}>
                                    Hinzufügen
                                </button>
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        )

    }

}

Permissions.propTypes = {
    resources: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string,
        actions: PropTypes.arrayOf(PropTypes.string)
    })),
    actions: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,
        value: PropTypes.string
    })),
    editable: PropTypes.bool.isRequired
};

Permissions.defaultProps = {
    editable: true
}

export default Permissions;