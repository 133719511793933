import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import DocumentValidation from './DocumentValidation';
import {Set} from 'immutable';

class Validation extends React.PureComponent {

    state = {
        validatedDocuments: Set(),
    };

    componentDidMount() {
        this.setState({validatedDocuments: Set(this.props.documents.filter((d) => d.state !== 'pending').map((d) => d.id))})
    }

    onValidationChange = (documentId, state) => {

        let validatedDocuments = this.state.validatedDocuments

        validatedDocuments = (state !== 'pending') ? validatedDocuments.add(documentId) : validatedDocuments.remove(documentId)

        this.setState({validatedDocuments: validatedDocuments});

    };

    onClick = async (navigateBack) => {

        let redirect = true;

        if (this.props.onComplete) {
            redirect = await this.props.onComplete();
        }

        if (!redirect) {
            return;
        }

        let data = {index: navigateBack};


        const result = await axios.post(`/administration/onboarding/${this.props.onboardingId}/transition`, data, {
            headers: {
                'Accept': 'application/json',
                'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
            }
        });

        if (result.status !== 200) {
            return;
        }

        location.href = result.data.location;

    };

    render() {

        return (

            <React.Fragment>
                {this.props.documents.map((doc, idx) => {
                    return (
                        <React.Fragment key={idx}>
                            <DocumentValidation index={idx} document={doc} validation={this.props.validation}
                                                onValidationChange={this.onValidationChange}/>
                        </React.Fragment>
                    )
                })}

                {this.props.children}
                {this.props.documents.length === this.state.validatedDocuments.size && this.props.completable &&
                    <div className='row flex-nowrap validation-navigation mb-2'>
                        <div className='col'>
                            <button className='btn btn-success btn-sm'
                                    onClick={() => this.onClick(false)}>{this.props.translations.completeAndNext}</button>
                        </div>
                        <div className='col-auto'>
                            <button className='btn btn-primary btn-sm'
                                    onClick={() => this.onClick(true)}>{this.props.translations.completeAndBack}</button>
                        </div>
                    </div>
                }
            </React.Fragment>

        )

    }

}

Validation.propTypes = {
    onboardingId: PropTypes.number.isRequired,
    documents: PropTypes.array.isRequired,
    validation: PropTypes.bool.isRequired,
    translations: PropTypes.object.isRequired,
    onComplete: PropTypes.func,
    completable: PropTypes.bool,
    children: PropTypes.node
};

Validation.defaultProps = {
    completable: true
}

export default Validation